<template>
  <PageCard pageName="FIN Admin">
    <template slot="page_content">
      <div class="">
        <div class="row">
          <div class="col-sm-3 offset-sm-9 mb-3">
            <b-input-group>
              <div class="input-group-prepend">
                <button class="btn btn-secondary" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
              <b-form-input
                type="text"
                v-model="filterText"
                placeholder="Search"
                ref="search"
              ></b-form-input>
            </b-input-group>
          </div>
        </div>
        <div
          class="row"
          v-for="(moduleChunk, index) in generalModuleChunks"
          :key="index"
        >
          <div
            class="col-sm-4"
            v-for="(module, index) in moduleChunk"
            :key="'1_' + index"
          >
            <ul class="list-group">
              <li
                class="list-group-item list-group-item-dark my-2 cursor-pointer module-link"
                v-if="module.module != ''"
              >
                <router-link :to="module.path" tag="div">
                  {{ module.module }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-sm-6"></div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-4">
            <h4>Advance Options</h4>
          </div>
        </div>
        <div
          class="row"
          v-for="(moduleChunk, index) in advanceModuleChunks"
          :key="'2_' + index"
        >
          <div
            class="col-sm-4"
            v-for="(module, index) in moduleChunk"
            :key="'3_' + index"
          >
            <ul class="list-group">
              <li
                class="list-group-item list-group-item-dark my-2 cursor-pointer module-link"
              >
                <router-link :to="module.path" tag="div">
                  {{ module.module }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-sm-6"></div>
        </div>
      </div>
    </template>
  </PageCard>
</template>

<script>
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
import PageCard from '@/modules/core/components/layouts/PageCard'
import _ from 'lodash'

export default {
  name: 'ProspectList',
  components: {
    PageCard
  },
  beforeCreate() {
    let hasPermission = store.getters['Ability/manageSuperAdminBoard']
    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  mounted() {
    this.$refs.search.$el.focus()
  },
  data() {
    return {
      filterText: null
    }
  },
  computed: {
    modulesAndUrls() {
      return [
        { module: 'Actions', path: '/super_admin/actions' },
        { module: 'App Configurations', path: 'app_configs' },
        { module: 'Authorization', path: 'authorization' },
        {
          module: 'Client Pricing Schedules',
          path: 'client_pricing_schedules'
        },
        {
          module: 'Fintech Strategy',
          path: '/super_admin/strategy/about'
        },
        {
          module: 'FIN Performographics',
          path: '/super_admin/technographics/performographics'
        },
        { module: 'Groups', path: 'all_groups' },
        { module: 'Manage Subscriptions', path: 'manage_subscrptions' },
        { module: 'Mock Users', path: 'mock_users' },
        { module: 'Navigator Groups', path: 'navigator_groups' },
        { module: 'Navigator Reports', path: 'navigator_reports' },
        {
          module: 'Report Access',
          path: '/super_admin/report_access/accessible_reports'
        },
        { module: 'Reports - Re Calculate', path: 'rerun_reports' },
        {
          module: 'Report Writer',
          path: '/super_admin/report_writer/select_report'
        },
        { module: 'Segmentations', path: 'segmentations' },
        { module: 'Upload Logo', path: '/super_admin/upload_logo' },
        { module: 'Users', path: 'all_users' },
        { module: 'User Activity Report', path: 'user_activity_report' },
        { module: 'User Edits - BDI', path: 'user_edits' },
        { module: 'User Order Report', path: 'user_orders_report' },
        {
          module: 'Pending User Access Report',
          path: 'user_access_request_report'
        },
        {
          module: 'Large Downloads Report',
          path: 'large_downloads_report'
        }
      ]
    },
    generalOptions() {
      return [
        { module: 'Users', path: 'all_users' },
        { module: 'Groups', path: 'all_groups' },
        { module: 'Copy Reports', path: 'copy_reports' },

        { module: 'Mock Users', path: 'mock_users' },
        {
          module: 'Client Pricing Schedules',
          path: 'client_pricing_schedules'
        },
        { module: 'Copy Queries', path: 'copy_queries' },

        {
          module: 'Pending User Access Report',
          path: 'user_access_request_report'
        },
        { module: 'Authorization', path: 'authorization' },
        { module: 'Upload Logo', path: '/super_admin/upload_logo' },

        { module: 'User Activity Report', path: 'user_activity_report' },
        {
          module: 'Report Access',
          path: '/super_admin/report_access/accessible_reports'
        },
        { module: 'User Edits - BDI', path: 'user_edits' },

        { module: 'User Order Report', path: 'user_orders_report' },
        { module: 'Upload User Guides', path: 'upload_user_guides' },
        { module: 'Reports - Re Calculate', path: 'rerun_reports' },

        {
          module: 'Large Downloads Report',
          path: 'large_downloads_report'
        },
        { module: '', path: '' },
        { module: 'Update FI Name', path: 'update_fi_name' }
      ]
    },
    advanceOptions() {
      return [
        { module: 'Actions', path: '/super_admin/actions' },
        { module: 'Navigator Groups', path: 'navigator_groups' },
        { module: 'Manage Subscriptions', path: 'manage_subscrptions' },

        {
          module: 'FIN Performographics',
          path: '/super_admin/technographics/performographics'
        },
        { module: 'Navigator Reports', path: 'navigator_reports' },
        {
          module: 'Fintech Strategy',
          path: '/super_admin/strategy/about'
        },

        { module: 'App Configurations', path: 'app_configs' },
        {
          module: 'Report Writer',
          path: '/super_admin/report_writer/select_report'
        },
        { module: 'Segmentations', path: 'segmentations' }
      ]
    },

    generalModuleChunks() {
      return _.chunk(this.filteredModules('general'), 3)
    },
    advanceModuleChunks() {
      return _.chunk(this.filteredModules('advance'), 3)
    },
    moduleChunks() {
      return _.chunk(this.filteredModules, 3)
    }
  },
  methods: {
    filteredModules(set) {
      let moduleList =
        set === 'general' ? this.generalOptions : this.advanceOptions
      if (!this.filterText) {
        return moduleList
      }
      return moduleList.filter(module => {
        return module.module
          .toLowerCase()
          .includes(this.filterText.toLowerCase())
      })
    }
  },
  watch: {
    filterText: {
      handler: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.list-group-item {
  border: none;
  font-weight: bold;

  &:hover {
    background-color: #b9bbbe;
  }
}
</style>
